import React from "react"
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import styles from "./six-week-series.module.css";
import Img from "gatsby-image";
import {Button, Row, Col} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";

const SixWeekSeriesPage = ({data}) => {

  return (
    <Layout className="site-content">
      <div className={styles.sixWeekSeriesContainer}>
        <div>
          <h1 className={globalStyle.entryTitle}>Six Week Series</h1>
        </div>
        <div className={styles.aboutWorkshop}>
          <h4>Join a Virtual Parenting Village</h4>
        </div>

        <div className={globalStyle.subTitle}>
          <p>
            Being a parent is amazing, but it also comes with its own unique challenges. Having other like-minded parents
            to share with makes all the difference. Our Virtual Parenting Villages are six-weeks long…a time for you to dive into
            community with a small group of like-minded moms and dads. Our incredible Parent Educators will facilitate these calls,
            each with a unique topic centered around baby, career, self, and family. </p>
        </div>

        <div className={styles.workshopsContent}>
          <Row>
            <Col className={[styles.workshopsItem, "col-md-6"].join(" ")} >
              <p>Parents of New Babies 0-6 Months</p>
              <Link to="/product/new-baby-virtual-village-0-6-months-1/">
                <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid}></Img>
                <Button className="btn btn-primary" className={styles.redirectTo} href="/product/new-baby-virtual-village-0-6-months-1/" value="Website" role="button">Join Baby Village</Button>
              </Link>
            </Col>

            <Col className={[styles.workshopsItem, "col-md-6"].join(" ")}>
              <p>Parents of Toddlers</p>
              <Link to="/product/parenting-toddlers-virtual-village/">
                <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid}></Img>
                <Button className="btn btn-primary" className={styles.redirectTo} href="/product/parenting-toddlers-virtual-village/" value="Website2" role="button">Join Toddle Village</Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
};

export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/parenting-village\/.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          name
        }
      }
    }
  }
`;

export default SixWeekSeriesPage
